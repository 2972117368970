import { css } from "@emotion/react";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MenuLinks from "@/components/atoms/Header/MenuLinks";
import Drawer from "@/components/molecules/Header/Drawer";
import HeaderLogo from "@/components/molecules/Header/HeaderLogo";
import useSearchPath from "@/hooks/useSearchPath";

const styles = css`
  width: 100%;
  height: 7.2rem;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  a.logo {
    display: block;
    width: 18.8rem;
    margin-left: 2rem;
  }

  .nav-txt {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-left: 2rem;
  }

  ul.nav {
    display: flex;
    justify-content: flex-end;
    margin: 0 2rem 0 auto;

    li {
      line-height: 1.1;
      font-weight: 700;
      margin-left: 2.4rem;

      &.useful {
        padding-left: 2.4rem;
        border-left: 1px solid;
      }
    }
  }

  @media (max-width: 768px) {
    height: 5rem;

    a.logo {
      width: 14rem;
      margin-left: 1.6rem;
    }

    .nav-txt {
      font-size: 1rem;
      margin-left: 1.2rem;
    }
  }
`;

const Navigation = () => {
  const [isActive, setIsActive] = useState(false);
  const searchPath = useSearchPath("delivery_status", "居抜き");
  const { pathname } = useLocation();

  const toggleMenu = useCallback(() => {
    setIsActive((prevIsActive) => !prevIsActive);
  }, []);

  const closeMenu = useCallback(() => {
    setIsActive(false);
  }, []);

  useEffect(() => {
    closeMenu();
  }, [pathname, closeMenu]);

  return (
    <nav css={styles}>
      <HeaderLogo />
      <MenuLinks searchPath={searchPath} />
      <Drawer
        isActive={isActive}
        toggleMenu={toggleMenu}
        closeMenu={closeMenu}
        searchPath={searchPath}
      />
    </nav>
  );
};

export default Navigation;
