import { useConsider, useSetConsider } from "@/contexts/Consider";

const useConsiders = () => {
  const data = useConsider();
  const setConsiders = useSetConsider();

  const ids = [
    ...new Set(
      data.map((consider) => {
        return consider.id;
      }),
    ),
  ];

  const includes = (buildingId, sectionInfoId) => {
    return data.some((consider) => {
      return consider.id === buildingId && consider.info === sectionInfoId;
    });
  };

  const toggle = (buildingId, sectionInfoId) => {
    let considerData = [{ id: buildingId, info: sectionInfoId }];
    const isInCluded = includes(buildingId, sectionInfoId);

    if (data.length > 0) {
      if (isInCluded) {
        considerData = data
          .map((consider) => {
            return consider.id === buildingId && consider.info === sectionInfoId ? null : consider;
          })
          .filter((replacedConsider) => replacedConsider !== null);
      } else {
        considerData = [...data, ...considerData];
      }
    }

    window.localStorage.setItem("consider", JSON.stringify(considerData));
    setConsiders(window.localStorage ? JSON.parse(window.localStorage.getItem("consider")) : []);
  };

  return { data, ids, includes, toggle, setConsiders };
};

export default useConsiders;
