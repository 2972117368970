import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";

import { Routes } from "@/Routes";
import ContextProvider from "@/contexts";
import reportWebVitals from "@/reportWebVitals";

import "@/styles/reset.css";
import "@/styles/common.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <HelmetProvider>
        <RouterProvider router={Routes} />
      </HelmetProvider>
    </ContextProvider>
  </React.StrictMode>,
);

reportWebVitals();
