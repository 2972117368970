export const initPropertyTypes = ["事務所", "店舗", "SOHO", "倉庫", "駐車場"];

const contractAreas = [
  30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 350, 400, 500, 600, 700, 800,
];

const createLimitObject = (value, label) => ({ value, label });

const lowerLimit = createLimitObject("", "下限なし");
const higherLimit = createLimitObject("", "上限なし");

export const initContractAreasFrom = [
  lowerLimit,
  ...contractAreas.map((number) => createLimitObject(number, `${number}㎡以上`)),
];

export const initContractAreasTo = [
  higherLimit,
  ...contractAreas.map((number) => createLimitObject(number, `${number}㎡以下`)),
];

export const initRentFeeTypes = [
  { value: "0", label: "総額" },
  { value: "1", label: "坪単価" },
  { value: "", label: "とくに予算なし" },
];

const rentFee = [
  200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000,
  2500000, 3000000, 4000000, 5000000,
];

const rentAreaFee = [
  10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 25000, 30000, 35000,
  40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000, 150000,
];

const lowerRentFee = [100000, ...rentFee];
const lowerRentAreaFee = [8000, 9000, ...rentAreaFee].filter((number) => number < 60000);

const createRentFeeObject = (number) => ({
  value: number,
  label: `${number / 10000}万円`,
});

const createRentAreaFeeObject = (number) => ({
  value: number,
  label: `${number.toLocaleString()}円`,
});

export const initRentFeeFrom = [lowerLimit, ...lowerRentFee.map(createRentFeeObject)];

export const initRentFeeTo = [higherLimit, ...rentFee.map(createRentFeeObject)];

export const initRentAreaFeeFrom = [lowerLimit, ...lowerRentAreaFee.map(createRentAreaFeeObject)];

export const initRentAreaFeeTo = [higherLimit, ...rentAreaFee.map(createRentAreaFeeObject)];

const unspecified = createLimitObject("", "指定なし");

export const initWalkingTimes = [
  unspecified,
  ...[3, 5, 10, 15, 20].map((number) => createLimitObject(number, `${number}分以内`)),
];

export const initAges = [
  unspecified,
  createLimitObject(0, "新築"),
  ...[5, 10, 15].map((number) => createLimitObject(number, `${number}年以内`)),
  createLimitObject(-1, "新耐震基準 （昭和56年6月以降建築確認取得）"),
];

export const initSeismicStandards = ["新耐震基準"];

export const conditions = [
  "1F",
  "2F以上",
  "地下",
  "オフィス街",
  "学生街",
  "繁華街",
  "観光地",
  "商店街",
  "商業施設内",
  "ロードサイド",
  "シーサイド",
  "重飲食可",
  "テラス",
  "専用ダクト",
  "全面ガラス張り",
  "個室",
  "エレベーター",
  "袖看板",
  "駐車場あり",
  "一棟貸し",
  "造作無償",
  "バーカウンター",
];

export const initSecurityTypes = ["機械警備", "有人+機械警備"];
export const initOa = ["あり"];
export const initAirConditioning = ["個別"];
export const initLocationFloorStart = [createLimitObject("1", "1階限定")];
export const initDeliveryStatus = ["スケルトン", "居抜き"];
export const initParking = ["あり"];
export const initToilet = ["男女別トイレ"];
export const initAutoLock = ["あり", "夜間オートロック"];
export const initElevator = ["あり"];
export const others = [
  "機械警備",
  "OAフロア",
  "1階限定",
  "スケルトン",
  "居抜き",
  "男女別トイレ",
  "オートロック",
  "エレベーター",
];

const sorts = ["おすすめ順", "新着順", "家賃が安い順", "家賃が高い順"];
export const initSorts = sorts.map((sort) => createLimitObject(sort, sort));
