import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import PageTop from "@/components/organisms/PageTop";

const Layout = ({ children }) => {
  return (
    <div id="wrapper" className="overflow">
      <Header />
      {children}
      <Footer />
      <PageTop />
    </div>
  );
};

export default Layout;
