import ConsiderProvider from "@/contexts/Consider";
import FixedNavVisibleProvider from "@/contexts/FixedNavVisible";

const ContextProvider = ({ children }) => {
  return (
    <ConsiderProvider>
      <FixedNavVisibleProvider>{children}</FixedNavVisibleProvider>
    </ConsiderProvider>
  );
};

export default ContextProvider;
