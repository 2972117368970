import { Link } from "react-router-dom";

import useConsiders from "@/hooks/useConsiders";

const FixedNav = () => {
  const { data } = useConsiders();

  return (
    <div className="fixed-nav">
      <Link className="consider-view" to="/consider">
        検討中リスト
        <span className="num">{data.length}</span>
      </Link>
      <Link className="request-btn" to="/form-request">
        物件リクエスト
      </Link>
      <Link className="contact-btn" to="/form-contact">
        お問い合わせ
      </Link>
    </div>
  );
};

export default FixedNav;
