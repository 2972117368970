import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL + "/api/v1/customer";

const fetcher = async (endpoint, params) => {
  return await axios
    .get(baseUrl + endpoint, {
      params: params ? params : undefined,
    })
    .then((res) => res.data);
};

export default fetcher;
