import { css } from "@emotion/react";

export const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  margin-left: auto;
  position: relative;
  z-index: 3;

  span,
  span:before,
  span:after {
    content: "";
    display: block;
    background: #000;
    height: 2px;
    width: 2.6rem;
    position: absolute;
    transition: 0.3s ease-in-out;
  }

  span {
    transition: background 0s 0.15s;

    &::before {
      bottom: 0.7rem;
      transform: rotate(0);
      transition: bottom 0.15s 0.15s, transform 0.15s;
    }

    &:after {
      bottom: -0.7rem;
      transform: rotate(0);
      transition: bottom 0.15s 0.15s, transform 0.15s;
    }
  }

  &.active {
    span {
      background: transparent;

      &::before {
        bottom: 0;
        transform: rotate(-35deg);
        transition: bottom 0.15s, transform 0.15s 0.15s;
      }

      &::after {
        bottom: 0;
        transform: rotate(35deg);
        transition: bottom 0.15s, transform 0.15s 0.15s;
      }
    }

  }
`;

const OpenButton = ({ isActive, handler }) => {
  return (
    <div id="nav-open" css={styles} className={isActive ? "sp active" : "sp"} onClick={handler}>
      <span />
    </div>
  );
};

export default OpenButton;
