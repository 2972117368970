import { Link } from "react-router-dom";

import CopyRight from "@/components/atoms/Footer/CopyRight";
import LowMenuLinks from "@/components/atoms/Footer/LowMenuLinks";

const RightContents = () => {
  return (
    <div className="right">
      <h2>物件を探す</h2>
      <ul className="footer-nav1">
        <li>
          <Link to="/property/minato">港区の物件</Link>
        </li>
        <li>
          <Link to="/property/shibuya">渋谷区の物件</Link>
        </li>
        <li>
          <Link className="consider-btn" to="/consider">
            検討中リスト
          </Link>
        </li>
      </ul>
      <ul className="footer-nav2">
        <li>
          <Link to="/search-by-station">駅・路線で探す</Link>
        </li>
        <li>
          <Link to="/search-by-area">エリアで探す</Link>
        </li>
        <li>
          <Link to="/search-by-address">住所で探す</Link>
        </li>
      </ul>
      <ul className="footer-nav3">
        {/* <li>
              <Link to="/column">お役立ち情報</Link>
            </li> */}
        {/* <li>
              <Link to="/news">お知らせ</Link>
            </li> */}
        <li>
          <Link to="/form-request">物件リクエスト</Link>
        </li>
        <li>
          <Link to="/form-contact">お問い合わせ</Link>
        </li>
      </ul>
      <LowMenuLinks isMobile />
      <CopyRight />
    </div>
  );
};

export default RightContents;
