import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import FixedNav from "@/components/organisms/Header/FixedNav";
import Navigation from "@/components/organisms/Header/Navigation";
import { useFixedNavVisible, useSetFixedNavVisible } from "@/contexts/FixedNavVisible";

const styles = css`
  margin-top: 7.2rem;
  position: relative;

  .fixed-nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
    background: #036835;
    padding: 1.5rem 2rem;
    border-radius: 0 0 0 1.6rem;
    position: fixed;
    right: 0;
    top: 7.2rem;
    z-index: 998;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4.2rem;
      border-radius: 0.8rem;
      padding: 0 1em;
      font-weight: 700;
      position: relative;

      &.consider-view {
        background: #fff;
        color: #61b92b;
      }

      &.request-btn {
        background: #61b92c;
        color: #fff;
      }

      &.contact-btn {
        color: #fff;
        border: 2px solid;
      }

      .num {
        display: inline-block;
        min-width: 2.1rem;
        line-height: 2.1rem;
        border-radius: 1.1rem;
        padding: 0 0.5rem;
        background: #ea3c64;
        color: #fff;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 700;
        position: absolute;
        right: -0.6rem;
        top: -0.6rem;
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 5rem;

    .fixed-nav {
      width: 100%;
      gap: 1.1rem;
      padding: 1rem 1.6rem;
      border-radius: 0;
      position: unset;

      a {
        height: 3.6rem;
        padding: 0;
      }
    }
  }
`;

const Header = () => {
  const fixedNavVisible = useFixedNavVisible();
  const setFixedNavVisible = useSetFixedNavVisible();
  const location = useLocation();
  const isFormPage = useMemo(() => location.pathname.includes("form"), [location]);

  useEffect(() => {
    setFixedNavVisible(!isFormPage);
  }, [isFormPage, setFixedNavVisible]);

  return (
    <header css={styles}>
      <Navigation />
      {fixedNavVisible && <FixedNav />}
    </header>
  );
};

export default Header;
