import { css } from "@emotion/react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const PageTopStyles = css`
  display: none;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 997;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    background-color: #115231;
    img {
      width: 1.6rem;
      margin-top: -0.2rem;
    }
  }
`;

const PageTop = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.innerHeight + scroll;
      const footHeight = document.querySelector("footer").offsetHeight;

      const pageTopElement = document.getElementById("pagetop");
      if (scroll < 400 || scrollHeight - scrollPosition <= footHeight) {
        pageTopElement.style.display = "none";
      } else {
        pageTopElement.style.display = "block";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="pagetop" css={PageTopStyles}>
      <Link to="#">
        <img src="/images/common/pagetop.svg" alt="" />
      </Link>
    </div>
  );
};

export default PageTop;
