import { lazy } from "react";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import App from "@/App";

// const ColumnArchive = lazy(() => import("@/features/ColumnArchive/pages"));
// const ColumnSingle = lazy(() => import("@/features/ColumnSingle/pages"));
const Consider = lazy(() => import("@/features/Consider/pages"));
const FormContact = lazy(() => import("@/features/FormContact/pages"));
const FormRequest = lazy(() => import("@/features/FormRequest/pages"));
const Home = lazy(() => import("@/features/home/pages"));
// const NewsArchive = lazy(() => import("@/features/NewsArchive/pages"));
// const NewsSingle = lazy(() => import("@/features/NewsSingle/pages"));
const PrivacyPolicy = lazy(() => import("@/pages/PrivacyPolicy"));
const PropertyArchive = lazy(() => import("@/features/PropertyArchive/pages"));
const PropertySingle = lazy(() => import("@/features/PropertySingle/pages"));
const SearchByAddress = lazy(() => import("@/features/SearchByAddress/pages"));
const SearchByArea = lazy(() => import("@/features/SearchByArea/pages"));
const SearchByStation = lazy(() => import("@/features/SearchByStation/pages"));
const Error = lazy(() => import("@/pages/Error"));

export const Routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<Error />}>
      <Route index element={<Home />} />
      <Route path="/consider" element={<Consider />} />
      {/* <Route path="/column" element={<ColumnArchive />} /> */}
      {/* <Route path="/column/:category/" element={<ColumnArchive />} /> */}
      {/* <Route path="/column/:category/:slug" element={<ColumnSingle />} /> */}
      <Route path="/form-contact" element={<FormContact />} />
      <Route path="/form-request" element={<FormRequest />} />
      {/* <Route path="/news" element={<NewsArchive />} /> */}
      {/* <Route path="/news/:slug" element={<NewsSingle />} /> */}
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/property" element={<PropertyArchive />} />
      <Route path="/property/:district" element={<PropertyArchive />} />
      <Route path="/property/:district/:baseInfoId/:id/" element={<PropertySingle />} />
      <Route path="/search-by-address" element={<SearchByAddress />} />
      <Route path="/search-by-area" element={<SearchByArea />} />
      <Route path="/search-by-station" element={<SearchByStation />} />
    </Route>,
  ),
);
