import { Link } from "react-router-dom";

const Logo = ({ isWhite }) => {
  const img = isWhite ? "logo_w.png" : "logo.png";

  return (
    <Link className="logo" to="/">
      <img src={`/images/common/${img}`} alt="テナントの森" />
    </Link>
  );
};

export default Logo;
