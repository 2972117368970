import { Suspense } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

import Loading from "@/components/atoms/Loading";
import Layout from "@/components/templates/Layout";
import usePageTracking from "@/hooks/usePageTracking";

const PageTracker = () => {
  usePageTracking();
  return null;
};

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Layout>
        <PageTracker />
        <Outlet />
      </Layout>
      <ScrollRestoration />
    </Suspense>
  );
};

export default App;
