import useCondition from "@/hooks/useCondition";

const useSearchPath = (name, value) => {
  const { conditions } = useCondition();
  const property = "/property";

  const targetCondition = conditions[name]
    ? conditions[name].find((condition) => condition.value === value)
    : false;

  const key = targetCondition ? targetCondition.key : "";

  if (name === "delivery_status") {
    return `${property}?ds=${key}`;
  } else {
    return `${property}`;
  }
};

export default useSearchPath;
