import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";

const gtmId = `GTM-${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`;

const usePageTracking = () => {
  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    TagManager.initialize({ gtmId: gtmId });
  }, []);

  useEffect(() => {
    const pagePath = pathname + search;
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: pagePath,
      },
    });
  }, [location]);
};

export default usePageTracking;
