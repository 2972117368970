import { useState, useMemo } from "react";

import useFetchData from "@/hooks/useFetchData";
import {
  initPropertyTypes,
  initContractAreasFrom,
  initContractAreasTo,
  initRentFeeTypes,
  initRentFeeFrom,
  initRentFeeTo,
  initRentAreaFeeFrom,
  initRentAreaFeeTo,
  initWalkingTimes,
  initAges,
  initAirConditioning,
  initSecurityTypes,
  initParking,
  initOa,
  initDeliveryStatus,
  initAutoLock,
  initElevator,
  initToilet,
  initLocationFloorStart,
  initSeismicStandards,
} from "@/utils/property";
import { concatCheckFlg } from "@/utils/search";

const useCondition = (searchParams) => {
  const { result, error } = useFetchData("/search/condition");
  const {
    property_type_name_ids,
    seismic_standard,
    security_type,
    oa,
    air_conditioning,
    delivery_status,
    parking,
    toilet,
    auto_lock,
    elevator,
  } = result;

  const getSearchParams = () => {
    const other = searchParams && searchParams.other;
    if (!other) return {};

    return Object.entries(other).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          acc[key] = value;
        }
      } else {
        if (value !== undefined && value !== null && value !== "") {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  };

  const otherSearchParams = getSearchParams();
  const otherPropertyTypeNameIds = otherSearchParams.property_type_name_ids
    ? otherSearchParams.property_type_name_ids
    : [];
  const otherSecurityType = otherSearchParams.security_type ? otherSearchParams.security_type : [];
  const otherOa = otherSearchParams.oa ? otherSearchParams.oa : [];
  const otherDeliveryStatus = otherSearchParams.delivery_status
    ? otherSearchParams.delivery_status
    : [];
  const otherToilet = otherSearchParams.toilet ? otherSearchParams.toilet : [];
  const otherAutoLock = otherSearchParams.auto_lock ? otherSearchParams.auto_lock : [];
  const otherElevator = otherSearchParams.elevator ? otherSearchParams.elevator : [];

  const getKeyConditions = useMemo(
    () => (name, init, conditions) => {
      if (conditions) {
        return init.map((value) => {
          const target = conditions.find((condition) => condition.value === value);
          return {
            name: name,
            value: target.key,
            label: value,
          };
        });
      } else {
        return init.map((value) => {
          return {
            name: name,
            ...value,
          };
        });
      }
    },
    [],
  );

  const searchSeismicStandards = useMemo(
    () =>
      getKeyConditions("seismicStandards", initSeismicStandards, seismic_standard).map(
        (seismicStandard) => {
          const { label } = seismicStandard;
          return {
            ...seismicStandard,
            label: label === "新耐震基準" ? "新耐震基準 （昭和56年6月以降建築確認取得）" : label,
          };
        },
      ),
    [getKeyConditions, seismic_standard],
  );

  const searchAirConditioning = useMemo(
    () =>
      concatCheckFlg(
        getKeyConditions("airConditioning", initAirConditioning, air_conditioning),
      ).map((airConditioning) => {
        const { label } = airConditioning;
        return {
          ...airConditioning,
          label: label === "個別" ? "個別空調" : label,
        };
      }),
    [getKeyConditions, air_conditioning],
  );

  const changeSearchValues = useMemo(
    () => (name, init, conditions, changeStr) => {
      return concatCheckFlg(getKeyConditions(name, init, conditions)).map((condition) => {
        const { label } = condition;
        return {
          ...condition,
          label: label === "あり" ? changeStr : label,
        };
      });
    },
    [getKeyConditions],
  );

  const [searchValues, setSearchValues] = useState({
    propertyTypeNameIds: concatCheckFlg(
      getKeyConditions("propertyTypeNameIds", initPropertyTypes, property_type_name_ids),
    ).map((condition) => {
      return {
        ...condition,
        checked: otherPropertyTypeNameIds.includes(condition.value),
      };
    }),
    contractAreaFrom: getKeyConditions("contractAreaFrom", initContractAreasFrom),
    contractAreaTo: getKeyConditions("contractAreaTo", initContractAreasTo),
    rentFeeType: getKeyConditions("rentFeeType", initRentFeeTypes),
    rentFeeFrom: getKeyConditions("rentFeeFrom", initRentFeeFrom),
    rentFeeTo: getKeyConditions("rentFeeTo", initRentFeeTo),
    rentAreaFeeFrom: getKeyConditions("rentAreaFeeFrom", initRentAreaFeeFrom),
    rentAreaFeeTo: getKeyConditions("rentAreaFeeTo", initRentAreaFeeTo),
    walkingTime: getKeyConditions("walkingTime", initWalkingTimes),
    age: getKeyConditions("age", initAges),
    seismicStandard: searchSeismicStandards,
    securityType: concatCheckFlg(
      getKeyConditions("securityType", initSecurityTypes, security_type),
    ).map((condition) => {
      return {
        ...condition,
        checked: otherSecurityType.includes(condition.value),
      };
    }),
    oa: changeSearchValues("oa", initOa, oa, "OAフロア").map((condition) => {
      return {
        ...condition,
        checked: otherOa.includes(condition.value),
      };
    }),
    airConditioning: searchAirConditioning,
    locationFloorStart: getKeyConditions("locationFloorStart", initLocationFloorStart),
    deliveryStatus: concatCheckFlg(
      getKeyConditions("deliveryStatus", initDeliveryStatus, delivery_status),
    ).map((condition) => {
      return {
        ...condition,
        checked: otherDeliveryStatus.includes(condition.value),
      };
    }),
    parking: changeSearchValues("parking", initParking, parking, "駐車場"),
    toilet: concatCheckFlg(getKeyConditions("toilet", initToilet, toilet)).map((condition) => {
      return {
        ...condition,
        checked: otherToilet.includes(condition.value),
      };
    }),
    autoLock: changeSearchValues("autoLock", initAutoLock, auto_lock, "オートロック").map(
      (condition) => {
        return {
          ...condition,
          checked: otherAutoLock.includes(condition.value),
        };
      },
    ),
    elevator: changeSearchValues("elevator", initElevator, elevator, "エレベーター").map(
      (condition) => {
        return {
          ...condition,
          checked: otherElevator.includes(condition.value),
        };
      },
    ),
  });

  const handleCheck = (e) => {
    const { name, value } = e.target;
    if (searchValues[name] === undefined) return false;
    const updatedSearchValues = searchValues[name].map((condition) => {
      if (condition.value === value) {
        return {
          ...condition,
          checked: !condition.checked,
        };
      }
      return condition;
    });
    setSearchValues({
      ...searchValues,
      [name]: updatedSearchValues,
    });
  };

  const handleCheckAll = (e) => {
    const { name } = e.target;
    if (searchValues[name] === undefined) return false;
    const updatedSearchValues = searchValues[name].map((condition) => {
      return {
        ...condition,
        checked: !condition.checked,
      };
    });
    setSearchValues({
      ...searchValues,
      [name]: updatedSearchValues,
    });
  };

  const checkedSearchValues = (options) => {
    return options.filter((option) => option.checked).map((option) => option.value);
  };

  const isNumberValue = (n) => (Number.isFinite(n) ? true : typeof n === "string" && !isNaN(n));

  const [selectedValues, setSelectedValues] = useState({
    contractAreaFrom: otherSearchParams.contract_area_from
      ? otherSearchParams.contract_area_from
      : "",
    contractAreaTo: otherSearchParams.contract_area_to ? otherSearchParams.contract_area_to : "",
    rentFeeType: isNumberValue(otherSearchParams.rent_fee_type)
      ? String(otherSearchParams.rent_fee_type)
      : "",
    rentFeeFrom: otherSearchParams.rent_fee_type === 0 ? otherSearchParams.rent_fee_from : "",
    rentFeeTo: otherSearchParams.rent_fee_type === 0 ? otherSearchParams.rent_fee_to : "",
    rentAreaFeeFrom: otherSearchParams.rent_fee_type === 1 ? otherSearchParams.rent_fee_from : "",
    rentAreaFeeTo: otherSearchParams.rent_fee_type === 1 ? otherSearchParams.rent_fee_to : "",
    walkingTime: otherSearchParams.walking_time ? otherSearchParams.walking_time : "",
    age: otherSearchParams.age ? otherSearchParams.age : "",
    seismicStandard: otherSearchParams.seismic_standard ? otherSearchParams.seismic_standard : "",
    locationFloorStart: otherSearchParams.location_floor_start
      ? String(otherSearchParams.location_floor_start)
      : "",
  });

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (selectedValues[name] === undefined) return false;
    setSelectedValues({
      ...selectedValues,
      [name]: value,
    });
  };

  const handleSelectAge = (e) => {
    const { name, value } = e.target;
    if (selectedValues[name] === undefined) return false;
    const updateValue = searchValues.seismicStandard.find(
      (obj) => obj.label === "新耐震基準 （昭和56年6月以降建築確認取得）",
    ).value;
    setSelectedValues({
      ...selectedValues,
      [name]: value,
      ["seismicStandard"]: value === "-1" ? updateValue : "",
    });
  };

  const resetConditions = () => {
    setSearchValues({
      ...searchValues,
      propertyTypeNameIds: concatCheckFlg(
        getKeyConditions("propertyTypeNameIds", initPropertyTypes, property_type_name_ids),
      ),
      seismicStandard: searchSeismicStandards,
      securityType: concatCheckFlg(
        getKeyConditions("securityType", initSecurityTypes, security_type),
      ),
      oa: changeSearchValues("oa", initOa, oa, "OAフロア"),
      airConditioning: searchAirConditioning,
      locationFloorStart: getKeyConditions("locationFloorStart", initLocationFloorStart),
      deliveryStatus: concatCheckFlg(
        getKeyConditions("deliveryStatus", initDeliveryStatus, delivery_status),
      ),
      parking: changeSearchValues("parking", initParking, parking, "駐車場"),
      toilet: concatCheckFlg(getKeyConditions("toilet", initToilet, toilet)),
      autoLock: changeSearchValues("autoLock", initAutoLock, auto_lock, "オートロック"),
      elevator: changeSearchValues("elevator", initElevator, elevator, "エレベーター"),
    });
    setSelectedValues({
      contractAreaFrom: "",
      contractAreaTo: "",
      rentFeeType: "",
      rentFeeFrom: "",
      rentFeeTo: "",
      rentAreaFeeFrom: "",
      rentAreaFeeTo: "",
      walkingTime: "",
      age: "",
      seismicStandard: "",
      locationFloorStart: "",
    });
  };

  const selectedRentFeeFrom =
    selectedValues.rentFeeType === "0"
      ? selectedValues.rentFeeFrom
      : selectedValues.rentFeeType === "1"
        ? selectedValues.rentAreaFeeFrom
        : selectedValues.rentFeeType;

  const selectedRentFeeTo =
    selectedValues.rentFeeType === "0"
      ? selectedValues.rentFeeTo
      : selectedValues.rentFeeType === "1"
        ? selectedValues.rentAreaFeeTo
        : selectedValues.rentFeeType;

  const params = {
    property_type_name_ids: checkedSearchValues(searchValues.propertyTypeNameIds),
    contract_area_type: selectedValues.contractAreaFrom || selectedValues.contractAreaTo ? 1 : "",
    contract_area_from: selectedValues.contractAreaFrom
      ? parseInt(selectedValues.contractAreaFrom)
      : selectedValues.contractAreaFrom,
    contract_area_to: selectedValues.contractAreaTo
      ? parseInt(selectedValues.contractAreaTo)
      : selectedValues.contractAreaTo,
    rent_fee_type: selectedValues.rentFeeType
      ? parseInt(selectedValues.rentFeeType)
      : selectedValues.rentFeeType,
    rent_fee_from: selectedRentFeeFrom ? parseInt(selectedRentFeeFrom) : selectedRentFeeFrom,
    rent_fee_to: selectedRentFeeTo ? parseInt(selectedRentFeeTo) : selectedRentFeeTo,
    walking_time: selectedValues.walkingTime
      ? parseInt(selectedValues.walkingTime)
      : selectedValues.walkingTime,
    age: selectedValues.age ? parseInt(selectedValues.age) : selectedValues.age,
    seismic_standard: selectedValues.seismicStandard,
    location_floor_start: selectedValues.locationFloorStart
      ? parseInt(selectedValues.locationFloorStart)
      : selectedValues.locationFloorStart,
    location_floor_end: selectedValues.locationFloorStart
      ? parseInt(selectedValues.locationFloorStart)
      : selectedValues.locationFloorStart,
    security_type: checkedSearchValues(searchValues.securityType),
    oa: checkedSearchValues(searchValues.oa),
    delivery_status: checkedSearchValues(searchValues.deliveryStatus),
    toilet: checkedSearchValues(searchValues.toilet),
    auto_lock: checkedSearchValues(searchValues.autoLock),
    elevator: checkedSearchValues(searchValues.elevator),
  };

  return {
    conditions: result,
    error,
    search: searchValues,
    handleCheck,
    handleCheckAll,
    selected: selectedValues,
    handleSelect,
    handleSelectAge,
    resetConditions,
    params,
  };
};

export default useCondition;
