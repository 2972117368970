import { css } from "@emotion/react";

import LowMenuLinks from "@/components/atoms/Footer/LowMenuLinks";
import FooterLogo from "@/components/molecules/Footer/FooterLogo";
import RightContents from "@/components/molecules/Footer/RightContents";

const styles = css`
  background: #261f14;
  color: #fff;
  font-size: 1.4rem;
  padding: 7.5rem 0 2rem;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 7.4rem;
    height: 9.7rem;
    background: url(/images/common/symbol-1.png) center bottom no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(72.6rem);
  }

  a {
    color: #fff;

    &.logo {
      display: block;
      width: 28.5rem;
      margin-top: 1.4rem;
    }
  }
  .content {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
  }

  .left {
    display: flex;
    flex-direction: column;
  }

  ul {
    &.low-nav {
      display: flex;
      flex-wrap: wrap;
      margin-top: auto;
      li {
        display: block;

        & + li {
          margin-left: 2em;
        }
      }
    }

    &.footer-nav1 {
      display: grid;
      grid-template-columns: repeat(3, 14.7rem);
      gap: 1.4rem;
      margin-bottom: 2.5rem;

      li {
        display: block;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 4.8rem;
          border-radius: 0.8rem;
          background: rgba(255, 255, 255, 0.1);
          font-size: 2rem;
          font-weight: 700;

          &.consider-btn {
            background: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }

    &.footer-nav2,
    &.footer-nav3 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;

      li {
        display: block;
        line-height: 1.1;

        & + li {
          padding-left: 1.6rem;
          margin-left: 1.6rem;
          border-left: 1px solid;
        }
      }
    }

    &.footer-nav2 {
      font-size: 1.6rem;
    }
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 400;
    opacity: 0.5;
    margin-bottom: 0.6em;
  }

  .copy {
    text-align: right;
    opacity: 0.5;
    margin-top: 3.5rem;
  }

  @media (max-width: 768px) {
    padding: 6rem 0;

    &::before {
      width: 5.4rem;
      height: 7rem;
      left: auto;
      transform: unset;
      right: 1.6rem;
    }

    .content {
      grid-template-columns: 1fr;
      gap: 3.5rem;
    }

    a.logo {
      width: 22rem;
    }

    h2 {
      font-size: 1.4rem;
    }
    ul {
      &.footer-nav1 {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 3.5rem;

        li:nth-of-type(3) {
          grid-area: 2 / 1 / 3 / 3;
        }

        li a {
          font-size: 1.8rem;
        }
      }

      &.footer-nav2,
      &.footer-nav3 {
        display: block;
        margin-top: 3rem;

        li {
          line-height: 1.7;
          font-size: 1.6rem;

          & + li {
            padding: 0;
            margin: 0.5em 0 0;
            border-left: none;
          }
        }
      }

      &.low-nav {
        margin-top: 3rem;

        li + li {
          margin-left: 1.5em;
        }
      }
    }

    .copy {
      text-align: left;
      margin-top: 2rem;
    }
  }
`;

const Footer = () => {
  return (
    <footer css={styles}>
      <div className="content">
        <div className="left">
          <FooterLogo />
          <LowMenuLinks />
        </div>
        <RightContents />
      </div>
    </footer>
  );
};

export default Footer;
