import useSWR from "swr";

import fetcher from "@/utils/fetcher";

const useFetchData = (targetEndpoint, targetParams) => {
  const { data, error } = useSWR(
    [targetEndpoint, targetParams],
    ([endpoint, params]) => fetcher(endpoint, params),
    {
      suspense: true,
    },
  );

  return { result: data, error: error };
};

export default useFetchData;
