import { Link } from "react-router-dom";

const MenuLinks = ({ searchPath }) => {
  return (
    <ul className="pc nav">
      <li>
        <Link to="/search-by-station">駅・路線で探す</Link>
      </li>
      <li>
        <Link to="/search-by-area">エリアで探す</Link>
      </li>
      <li>
        <Link to="/search-by-address">住所で探す</Link>
      </li>
      <li>
        <Link to="/#propertyType">物件種別</Link>
      </li>
      <li>
        <Link to={searchPath}>居抜き物件</Link>
      </li>
      {/* <li className="useful">
        <Link to="/column">お役立ち情報</Link>
      </li> */}
    </ul>
  );
};

export default MenuLinks;
