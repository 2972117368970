import { css } from "@emotion/react";

const styles = css`
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  overflow-y: scroll;
  overscroll-behavior: contain;
  -ms-overflow-: none;
  scrollbar-width: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    opacity: 0.5;
    pointer-events: inherit;
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% + 1px);
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const DrawerBg = ({ isActive, handler }) => {
  return <div id="nav-bg" css={styles} className={isActive ? "active" : ""} onClick={handler} />;
};

export default DrawerBg;
