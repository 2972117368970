import Logo from "@/components/atoms/Logo";

const HeaderLogo = () => (
  <>
    <Logo />
    <div className="nav-txt">
      港区・渋谷区に特化！
      <br />
      オフィス・店舗検索サイト
    </div>
  </>
);

export default HeaderLogo;
