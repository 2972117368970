import Logo from "@/components/atoms/Logo";

const FooterLogo = () => (
  <>
    <p>
      港区・渋谷区に特化！
      <br className="sp" />
      オフィス・店舗検索サイト
    </p>
    <Logo isWhite />
  </>
);

export default FooterLogo;
