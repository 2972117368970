import { css } from "@emotion/react";
import { Link } from "react-router-dom";

export const styles = css`
  display: block;
  width: 80%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
  -ms-overflow-: none;
  scrollbar-width: none;
  transform: translateX(100%);
  transition: transform 0.3s;

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% + 1px);
    position: absolute;
    left: 0;
    top: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    transform: translateX(0);
  }

  .content {
    min-height: 100%;
    padding: 7rem 3rem 16rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 23.4rem;
      height: 16.2rem;
      background: url(/images/common/symbol-4.png) center bottom no-repeat;
      background-size: contain;
      position: absolute;
      right: -4rem;
      bottom: 0;
      pointer-events: none;
    }
  }
`;

const DrawerMenuLinks = ({ isActive, searchPath }) => {
  return (
    <div id="nav-content" css={styles} className={`sp ${isActive ? "active" : ""}`}>
      <div className="content">
        <ul className="sp-nav1">
          <li>
            <Link to="/">TOP</Link>
          </li>
          <li>
            <Link to="/search-by-station">駅・路線で探す</Link>
          </li>
          <li>
            <Link to="/search-by-area">エリアで探す</Link>
          </li>
          <li>
            <Link to="/search-by-address">住所で探す</Link>
          </li>
          <li>
            <Link to="/#propertyType">物件種別</Link>
          </li>
          <li>
            <Link to={searchPath}>居抜き物件</Link>
          </li>
        </ul>
        <ul className="sp-nav2">
          {/* <li>
            <Link to="/column">お役立ち情報</Link>
          </li> */}
          {/* <li>
            <Link to="/news">お知らせ</Link>
          </li> */}
          <li>
            <Link to="/consider">検討中リスト</Link>
          </li>
          <li>
            <Link to="/form-request">物件リクエスト</Link>
          </li>
          <li>
            <Link to="/form-contact">お問い合わせ</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DrawerMenuLinks;
