import { useState, createContext, useContext } from "react";

let storageConsiderData = window.localStorage
  ? JSON.parse(window.localStorage.getItem("consider"))
  : [];
if (storageConsiderData === null) {
  storageConsiderData = [];
}

const considersContext = createContext();
const setConsidersContext = createContext();

const ConsiderProvider = ({ children }) => {
  const [considers, setConsiders] = useState(storageConsiderData);

  return (
    <considersContext.Provider value={considers}>
      <setConsidersContext.Provider value={setConsiders}>{children}</setConsidersContext.Provider>
    </considersContext.Provider>
  );
};

export const useConsider = () => useContext(considersContext);
export const useSetConsider = () => useContext(setConsidersContext);

export default ConsiderProvider;
