import dayjs from "dayjs";
import "dayjs/locale/ja";

const CopyRight = () => {
  const year = dayjs().year();

  return (
    <div className="copy">
      <small>©︎ T-CROSS {year}</small>
    </div>
  );
};
export default CopyRight;
