import { useState, createContext, useContext } from "react";

const fixedNavVisibleContext = createContext();
const setFixedNavVisibleContext = createContext();

const FixedNavVisibleProvider = ({ children }) => {
  const [visible, setVisible] = useState(true);

  return (
    <fixedNavVisibleContext.Provider value={visible}>
      <setFixedNavVisibleContext.Provider value={setVisible}>
        {children}
      </setFixedNavVisibleContext.Provider>
    </fixedNavVisibleContext.Provider>
  );
};

export const useFixedNavVisible = () => useContext(fixedNavVisibleContext);
export const useSetFixedNavVisible = () => useContext(setFixedNavVisibleContext);

export default FixedNavVisibleProvider;
