import DrawerBg from "@/components/atoms/Header/DrawerBg";
import DrawerMenuLinks from "@/components/atoms/Header/DrawerMenuLinks";
import OpenButton from "@/components/atoms/Header/OpenButton";

const Drawer = ({ isActive, toggleMenu, closeMenu, searchPath }) => {
  return (
    <>
      <OpenButton isActive={isActive} handler={toggleMenu} />
      <DrawerBg isActive={isActive} handler={closeMenu} />
      <DrawerMenuLinks isActive={isActive} searchPath={searchPath} />
    </>
  );
};

export default Drawer;
