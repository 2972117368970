import { Link } from "react-router-dom";

const LowMenuLinks = ({ isMobile }) => {
  const style = isMobile ? "sp" : "pc";

  return (
    <ul className={`low-nav ${style}`}>
      <li>
        <a href="https://tcross.jp/" target="_blank" rel="noopener noreferrer">
          運営会社
        </a>
      </li>
      <li>
        <Link to="/privacy-policy">利用規約</Link>
      </li>
      <li>
        <Link to="/privacy-policy">プライバシーポリシー</Link>
      </li>
    </ul>
  );
};

export default LowMenuLinks;
